define("discourse/plugins/discourse-reactions/discourse/routes/user-notifications-reactions-received", ["exports", "discourse/routes/discourse", "discourse/plugins/discourse-reactions/discourse/models/discourse-reactions-custom-reaction"], function (_exports, _discourse, _discourseReactionsCustomReaction) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  function _defineProperty(obj, key, value) { key = _toPropertyKey(key); if (key in obj) { Object.defineProperty(obj, key, { value: value, enumerable: true, configurable: true, writable: true }); } else { obj[key] = value; } return obj; }
  function _toPropertyKey(arg) { var key = _toPrimitive(arg, "string"); return typeof key === "symbol" ? key : String(key); }
  function _toPrimitive(input, hint) { if (typeof input !== "object" || input === null) return input; var prim = input[Symbol.toPrimitive]; if (prim !== undefined) { var res = prim.call(input, hint || "default"); if (typeof res !== "object") return res; throw new TypeError("@@toPrimitive must return a primitive value."); } return (hint === "string" ? String : Number)(input); }
  class UserNotificationsReactionsReceived extends _discourse.default {
    constructor() {
      super(...arguments);
      _defineProperty(this, "templateName", "user-activity-reactions");
      _defineProperty(this, "controllerName", "user-activity-reactions");
      _defineProperty(this, "queryParams", {
        acting_username: {
          refreshModel: true
        },
        include_likes: {
          refreshModel: true
        }
      });
    }
    model(params) {
      return _discourseReactionsCustomReaction.default.findReactions("reactions-received", this.modelFor("user").get("username"), {
        actingUsername: params.acting_username,
        includeLikes: params.include_likes
      });
    }
    setupController(controller, model) {
      let loadedAll = model.length < 20;
      this.controllerFor("user-activity-reactions").setProperties({
        model,
        canLoadMore: !loadedAll,
        reactionsUrl: "reactions-received",
        username: this.modelFor("user").get("username"),
        actingUsername: controller.acting_username,
        includeLikes: controller.include_likes
      });
      this.controllerFor("application").set("showFooter", loadedAll);
    }
  }
  _exports.default = UserNotificationsReactionsReceived;
});